import React, { useEffect, useState } from "react";
import { AppstoreOutlined, MailOutlined, SettingOutlined, MenuOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Menu, Dropdown, Grid, Space } from "antd";

const { useBreakpoint } = Grid;

const MainMenu = ({ items, selectedItem, onSelectItem }) => {
  const [current, setCurrent] = useState("");

  const onClick = e => {
    if (e.key !== "external") {
      setCurrent(e.key);
    }
    onSelectItem(e.key);
  };

  useEffect(() => {
    setCurrent(selectedItem);
  }, [selectedItem]);

  const screens = useBreakpoint();

  return screens.xs ? (
    <Dropdown menu={{ items }}>
      <a onClick={e => e.preventDefault()}>
        <Space>
          <MenuOutlined />
        </Space>
      </a>
    </Dropdown>
  ) : (
    <Menu
      disabledOverflow
      mode="horizontal"
      onClick={onClick}
      selectedKeys={[current]}
      items={items}
      style={{ border: "unset" }}
    />
  );
};

export default MainMenu;
