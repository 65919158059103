/* eslint-disable react/no-unknown-property */
import React, { useEffect, useState } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connectAutoDispatch } from "redux/reduxConnect";
import { withRouter } from "react-router-dom";
import ProductItem from "components/plans/ProductItem";
import ChromeStoreIcon from "static/img/chromeicon.png";
import AppStoreIcon from "static/img/appstoreicon.png";
import GooglePlayIcon from "static/img/googleplayicon.png";
import GoogleWorkspaceMarketplaceImg from "static/img/google-workspace-marketplace.png";
import moment from "moment";
import { CustomPricingTable, StoreDownloadIcon, StoreDownloadWrapper } from "./plan.style";
import {
  getProductPlanRequest,
  getCurrentPluginsRequest,
  getCurrentPluginsAddedRequest
} from "redux/actions/PlanActions";
import { getUserInfoAppRequest } from "redux/actions/UserActions";
import { stripeGetPricesRequest } from "redux/actions/StripeActions";
import { Tooltip } from "antd";

const features = [
  {
    name: "2-sub accounts",
    plans: ["Starter Plan", "Advanced Plan"],
    helper: "Share secondary accounts with your team for efficient collaboration."
  },
  {
    name: "ScanEZ mobile app",
    plans: ["Advanced Plan"],
    helper: "Scan and analyze products on the go."
  },
  {
    name: "Google Sheets Add-on",
    plans: ["Advanced Plan"],
    helper: "Retrieve and analyze data seamlessly within Google Sheets."
  },
  {
    name: "Sell Restriction, Hazmat, Brand IP Checker",
    plans: ["Starter Plan", "Advanced Plan"],
    helper: "Quickly verify if a product is sellable on Amazon, flagged as hazardous, or associated with potential brand IP issues."
  },
  {
    name: "FBA/FBM Calculator, Stock Checker, Variations Viewer",
    plans: ["Advanced Plan"],
    helper: "Calculate FBA and FBM profits, track competitor inventory, and analyze all product variations with detailed sales data."
  },
  {
    name: "Plugins: Insight360, AZNotes, History, Sales Estimation...",
    plans: ["Advanced Plan"],
    helper: "Unlock the complete set of powerful plugins to enhance your Amazon selling experience"
  },
];

const AZInsightPlan = ({
  planState,
  stripeState,
  interval,
  userState,
  changeInterval,
  intervalLables,
  openRenewConfirmation,
  openCheckoutUrl,
  openErrorDialog
}) => {
  const {
    userInfo: {
      stripePriceKey,
      isInTrial,
      isNotStartTrial,
      isOutOfTrial,
      plan,
      productType,
      startTrialTime,
      freeTrialType,
      startedFreeTrialAt,
      isNotSpToken
    }
  } = userState;

  const { currentPlans } = planState;
  const { prices } = stripeState;

  const [states, setStates] = useState({
    plans: {},
    intervals: [],
    activeInterval: null,
    isStartTrial: false,
    loginToStartTrial: false,
    isEndTrial: false
  });

  useEffect(() => {
    const availablePlans = currentPlans.data
      .map(d => {
        const priorities = {
          HAZability_PLAN_MONTHLY: 1,
          HAZability_PLAN: 2,
          AZInsight_PLAN_MONTHLY: 3,
          AZInsight_PLAN: 4
        };
        const price = prices.data.find(price => price.metadata && price.metadata.key === d.id);
        if (price) {
          let hide = true;
          let upgrade = false;
          if (!stripePriceKey || stripePriceKey === price.metadata.key) {
            hide = false;
          } else if (
            priorities[price.metadata.key] > priorities[stripePriceKey] &&
            stripePriceKey.replace("_MONTHLY", "") !== price.metadata.key.replace("_MONTHLY", "")
          ) {
            upgrade = true;
          }

          return {
            ...d,
            hide,
            upgrade,
            subscribed: stripePriceKey === price.metadata.key,
            priceId: price.id,
            key: price.metadata.key,
            mode: price.type === "recurring" ? "subscription" : "payment"
          };
        }
        return d;
      })
      .filter(d => d.priceId && (!d.hide || d.upgrade));

    const plans = _.groupBy(
      _.orderBy(availablePlans, "amount").filter(item => item.interval !== "Lifetime"),
      "interval"
    );

    const intervals = Object.keys(plans).reverse();
    const availableIntervals = intervals.filter(interval => plans[interval]);
    let activeInterval = interval;
    if (availableIntervals.length === 1) {
      [activeInterval] = availableIntervals;
    }

    const isStartTrial = !plan && !productType && isInTrial;
    const loginToStartTrial = !isInTrial && isNotStartTrial && !isOutOfTrial;
    const isEndTrial = !plan && !productType && isOutOfTrial;

    setStates({
      plans,
      intervals,
      activeInterval,
      isStartTrial,
      loginToStartTrial,
      isEndTrial
    });
  }, [userState, planState, stripeState, interval]);

  return (
    <CustomPricingTable>
      <div className="tabs is-toggle is-centered">
        <ul>
          {states.intervals
            .filter(interval => states.plans[interval])
            .map(i => (
              <li key={i} className={`${i === states.activeInterval ? "is-active" : ""}`}>
                <a onClick={() => changeInterval(i)}>{intervalLables[i.toLowerCase()]}</a>
              </li>
            ))}
        </ul>
      </div>
      {states.isStartTrial && (startTrialTime || startedFreeTrialAt) ? (
        !isNotSpToken ? (
          <div className="is-trial">
            <h1>You're currently on the AZInsight Advanced 30-day free trial.</h1>
            <div>
              <p className="start">
                Your free trial started on{" "}
                {moment(startTrialTime || startedFreeTrialAt)
                  .local()
                  .format("MMM-DD-YYYY")}
              </p>
              <p className="end">
                Your free trial will end on{" "}
                {moment(startTrialTime || startedFreeTrialAt)
                  .local()
                  .add(freeTrialType === "30" ? 30 : 10, "days")
                  .format("MMM-DD-YYYY")}
              </p>
            </div>
          </div>
        ) : (
          <div className="trial-not-sp">
            <h1>You're currently on the AZInsight Advanced Limited trial</h1>
            <div>
              <p className="end">Your Trial will end once you reach 40 ASINs</p>
            </div>
          </div>
        )
      ) : null}

      {states.loginToStartTrial ||
        (states.isStartTrial && !(startTrialTime || startedFreeTrialAt)) ? (
        <div className="not-yet-trial">
          <h1>
            You have not started the free trial, to start your AZInsight Advanced free trial, make
            sure that you have installed AZInsight on your Chrome browser by{" "}
            <a
              href="https://help.asinzen.com/installazinsight"
              target="_blank"
              rel="noopener noreferrer"
            >
              clicking here
            </a>
            .
          </h1>
        </div>
      ) : null}

      {states.isEndTrial ? (
        <div className="end-trial">
          <h1>
            Your free trial has ended, you can continue using AZInsight Advanced by subscribing
            below.
          </h1>
        </div>
      ) : null}

      <div className="note-az-plans">Promo codes or discounts do not apply to monthly plans</div>

      <div className="pricing-table is-comparative">
        <div className="pricing-plan is-features">
          <div className="plan-header">Features</div>
          <div className="plan-price">
            <span className="plan-price-amount">&nbsp;</span>
          </div>
          <div className="plan-items">
            {features.map((feature, index) => (
              <div className="plan-item" key={index}>
                {feature.name}{" "}
                <span
                  style={{ cursor: "pointer" }}
                  className={feature.name.toLowerCase().replace(/\W+/g, "_")}
                  id={feature.name.toLowerCase().replace(/\W+/g, "_")}
                >
                  <Tooltip title={feature.helper}>
                    <i className="fa fa-info-circle" aria-hidden="true" />
                  </Tooltip>
                </span>
              </div>
            ))}
          </div>
          <div className="plan-footer">
            <span style={{ color: "#7a7a7a", fontSize: "0.9em" }}>
              Not sure what is the best plan for you? Click{" "}
              <a href="https://help.asinzen.com/RNEH" target="_blank" rel="noopener noreferrer">
                here
              </a>{" "}
              to learn differences between the Starter and the Advanced.
            </span>
          </div>
        </div>
        {states.plans[states.activeInterval] &&
          states.plans[states.activeInterval]
            .map(d => {
              const priorities = {
                HAZability_PLAN_MONTHLY: 1,
                HAZability_PLAN: 2,
                AZInsight_PLAN_MONTHLY: 3,
                AZInsight_PLAN: 4
              };
              const price = stripeState.prices.data.find(
                price => price.metadata && price.metadata.key === d.id
              );
              if (price) {
                let hide = true;
                let upgrade = false;
                if (!stripePriceKey || stripePriceKey === price.metadata.key) {
                  hide = false;
                } else if (priorities[price.metadata.key] > priorities[stripePriceKey]) {
                  upgrade = true;
                }
                return {
                  ...d,
                  hide,
                  upgrade,
                  subscribed: stripePriceKey === price.metadata.key,
                  priceId: price.id,
                  key: price.metadata.key,
                  mode: price.type === "recurring" ? "subscription" : "payment"
                };
              }
              return d;
            })
            .filter(d => d.priceId && (!d.hide || d.upgrade))
            .map((d, k) => {
              return (
                <ProductItem
                  onUpdateSubscription={openRenewConfirmation}
                  onSubscribe={product => openCheckoutUrl(product)}
                  key={`product-item-${d.id}`}
                  product={d}
                  features={features}
                  userInfo={userState.userInfo}
                  showError={openErrorDialog}
                />
              );
            })}
      </div>
      <div className="pricing-table is-comparative">
        <StoreDownloadWrapper className="pricing-plan">
          <div>
            <strong>Chrome Extension</strong>
          </div>
          <StoreDownloadIcon>
            <a
              href="https://chrome.google.com/webstore/detail/azinsight-amazon-fba-prod/gefiflkplklbfkcjjcbobokclopbigfg"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={ChromeStoreIcon} />
            </a>
          </StoreDownloadIcon>
        </StoreDownloadWrapper>
        <StoreDownloadWrapper className="pricing-plan">
          <div>
            <strong>Mobile Phone Apps</strong>
          </div>
          <StoreDownloadIcon>
            <a
              href="https://apps.apple.com/us/app/scanez/id1523943349"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={AppStoreIcon} />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.asinzen.ezscan"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={GooglePlayIcon} />
            </a>
          </StoreDownloadIcon>
        </StoreDownloadWrapper>
        <StoreDownloadWrapper className="pricing-plan">
          <div>
            <strong>Google Sheets Add-on</strong>
          </div>
          <StoreDownloadIcon>
            <a
              href="https://workspace.google.com/marketplace/app/asinzen_insight/1021539709964?pann=b"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Get it from the Google Workspace Marketplace"
              style={{ maxWidth: 350 }}
            >
              <img alt="Google Workspace Marketplace badge" alt-text="Get it from the Google Workspace Marketplace" src="https://workspace.google.com/static/img/marketplace/en/gwmBadge.svg?" style={{ height: 68 }} />
            </a>
          </StoreDownloadIcon>
        </StoreDownloadWrapper>
      </div>
    </CustomPricingTable>
  );
};

AZInsightPlan.propTypes = {
  interval: PropTypes.string,
  intervalLables: PropTypes.object,
  openRenewConfirmation: PropTypes.func,
  openCheckoutUrl: PropTypes.func,
  changeInterval: PropTypes.func,
  openErrorDialog: PropTypes.func
};

AZInsightPlan.defaultProps = {
  interval: null,
  intervalLables: null,
  openRenewConfirmation: null,
  openCheckoutUrl: null,
  changeInterval: null,
  openErrorDialog: null
};

const withConnect = connectAutoDispatch(
  ({ userState, planState, stripeState }) => ({
    userState,
    planState,
    stripeState
  }),
  {
    getProductPlanRequest,
    getCurrentPluginsRequest,
    getCurrentPluginsAddedRequest,
    getUserInfoAppRequest,
    stripeGetPricesRequest
  }
);

export default compose(withRouter, withConnect)(AZInsightPlan);
