import React, { useEffect, useState } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { alpha } from "@mui/material/styles";
import { withStyles } from "@mui/styles";
import HelpIcon from "@mui/icons-material/Help";
import MoreIcon from "@mui/icons-material/MoreVert";
import { NavLink, withRouter } from "react-router-dom";
import constants from "utils/constants";
import Logo from "static/img/asinzenmainlogo.png";
import TokenIcon from "static/img/token-icon.png";
import { connectAutoDispatch } from "redux/reduxConnect";
import { userPropTypes, userInitState } from "redux/reducers/UserReducer";
import { apiGetPortalUrl, apiGetAsinzenTokens } from "api";
import MainMenu from "./Navs/MainMenu";
import {
  Layout,
  Space,
  Dropdown,
  Avatar,
  Row,
  Col,
  Button,
  Image,
  Typography,
  Tooltip,
  Flex,
  Card,
  Statistic,
  Popover,
  message
} from "antd";
import type { MenuProps } from "antd";
import { LinkMenuItems, LabelMainMenuItems } from "../../constants/navigation";
import { red } from "@mui/material/colors";

const ASINZEN_TOKENS = {
  freeToken: "Free",
  subscription: "Subscription",
  onetime: "One time"
};

const isExpired = d => {
  const nowDate = new Date();
  const expiredDate = new Date(d);
  return nowDate.getTime() >= expiredDate.getTime();
};

const AccountMenuItems = (user, onOpenStripe, onSignOut) => [
  {
    label: (
      <strong>
        {user.firstName} {user.lastName}
      </strong>
    )
  },
  {
    type: "divider"
  },
  user.accountType === "USER" && {
    label: (
      <NavLink exact to="/pricing-plans">
        Pricing Plans
      </NavLink>
    ),
    key: "pricing-plans"
  },
  user.registerFrom !== "PROMOTER" &&
  user.accountType === "USER" && {
    label: (
      <NavLink exact to="/asin-zen-insight/account-management">
        Sub-Accounts
      </NavLink>
    ),
    key: "account-management"
  },
  user.registerFrom !== "PROMOTER" &&
  user.accountType === "USER" && {
    label: (
      <NavLink exact to="/user/amazon-integration">
        Amazon Intergration
      </NavLink>
    ),
    key: "amazon-integration"
  },
  user.accountType === "USER" && {
    label: <a onClick={onOpenStripe}>Billing</a>,
    key: "billing"
  },
  user.accountType === "USER" && {
    label: (
      <NavLink exact to="/user/change-password">
        Profile Settings
      </NavLink>
    ),
    key: "change-password"
  },
  {
    label: <a onClick={onSignOut}>Sign Out</a>,
    key: "sign-out"
  }
];

const HelperSupportItems = user => [
  {
    label: (
      <a href="https://support.asinzen.com" target="_blank" rel="noopener noreferrer">
        Support Portal
      </a>
    ),
    key: "support-portal"
  },
  user.supportType === "enterprise" && {
    label: (
      <NavLink exact to="/enterprise-support">
        Enterprise Support
      </NavLink>
    ),
    key: "support-enterprise"
  },
  {
    label: (
      <a href="https://learn.asinzen.com" target="_blank" rel="noopener noreferrer">
        Learning portal
      </a>
    ),
    key: "learning-portal"
  },
  {
    label: (
      <a
        href="https://help.asinzen.com/asinzenyoutubechannel"
        target="_blank"
        rel="noopener noreferrer"
      >
        Youtube Channel
      </a>
    ),
    key: "youtube-channel"
  }
];

const LabelMainMenuItemsMember = user => [
  user.accessProducts && LabelMainMenuItems.product,
  user.accessSeller && LabelMainMenuItems.seller,
  user.accessHistory && LabelMainMenuItems.history
];

const { Header: HeaderAnt } = Layout;

const HeaderBar = ({ userInfo, classes, location, history }) => {
  const [selectMenuItem, setSelectMenuItem] = useState("");
  const [tokens, setTokens] = useState();

  const handleOnSelectMenuItem = item => {
    if (item !== "external") {
      setSelectMenuItem(item);
      const link = LinkMenuItems.filter(i => i.key === item);
      history.push(link[0].link);
    }
  };

  const handleOnSignOut = () => {
    localStorage.removeItem(constants.KEY_LOCAL_TOKEN);
    localStorage.removeItem(constants.KEY_LOCAL_STORAGE);
    history.push("/sign-in");
  };

  const detectHighlightTab = path => {
    const linkItem = LinkMenuItems.find(item => {
      if (path === "/") {
        const searchParams = location.search;
        if (searchParams) {
          return item.link.includes(searchParams);
        }
      }

      return item.link === path;
    });

    if (linkItem) {
      setSelectMenuItem(linkItem.key);
    } else {
      setSelectMenuItem(null);
    }
  };

  const handleOnGetAsinzenTokens = async user => {
    return await apiGetAsinzenTokens({
      email: user.email
    })
      .then(data => setTokens(data))
      .catch(e => message.error(e.message));
  };

  const handleOnOpenStripePortal = async () => {
    const resp = await apiGetPortalUrl({
      returnUrl: window.location.href
    });
    if (resp.url) {
      window.location.href = resp.url;
    }
  };

  useEffect(() => {
    detectHighlightTab(location.pathname);
    handleOnGetAsinzenTokens(userInfo);
  }, [location?.pathname]);

  const mainMenuItems =
    userInfo.accountType === "USER"
      ? Object.values(LabelMainMenuItems)
      : LabelMainMenuItemsMember(userInfo);

  return (
    <HeaderAnt className="header" style={{ background: "#ffffff", height: "auto" }}>
      <Row align="middle">
        <Col
          xs={24}
          lg={3}
          style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        >
          <NavLink exact to="/" style={{ display: "flex" }}>
            <img width="135" src={Logo} />
          </NavLink>
        </Col>
        <Col xs={20} lg={20}>
          <MainMenu
            items={mainMenuItems}
            selectedItem={selectMenuItem}
            onSelectItem={handleOnSelectMenuItem}
          />
        </Col>
        <Col xs={4} lg={1}>
          <Space style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
            {tokens && (
              <Popover
                title={
                  <Space>
                    <Flex align="center" justify="space-between" style={{ width: 200 }}>
                      <div>Total of Tokens</div>
                      <Statistic
                        value={
                          tokens.freeToken.tokens +
                          tokens.subscription.tokens +
                          tokens.onetime.tokens
                        }
                        valueStyle={{ fontSize: 14 }}
                      />
                    </Flex>
                  </Space>
                }
                content={
                  <Flex vertical>
                    {Object.keys(tokens).map(t => (
                      <Flex key={t} vertical>
                        <div>
                          <strong>{ASINZEN_TOKENS[t]}</strong>{" "}
                          {tokens[t].expiredIn && isExpired(tokens[t].expiredIn) && (
                            <span style={{ fontSize: 12, color: "red" }}>
                              (Expired on{" "}
                              {moment(tokens[t].expiredIn)
                                .local()
                                .format("MMM DD, YYYY")}
                              )
                            </span>
                          )}
                        </div>
                        <Flex
                          align="center"
                          justify="space-between"
                          style={{ width: 200, marginLeft: 10 }}
                        >
                          <div>Remaining</div>
                          <Statistic value={tokens[t].remaining} valueStyle={{ fontSize: 13 }} />
                        </Flex>
                        <Flex
                          align="center"
                          justify="space-between"
                          style={{ width: 200, marginLeft: 10 }}
                        >
                          <div>Total</div>
                          <Statistic value={tokens[t].tokens} valueStyle={{ fontSize: 13 }} />
                        </Flex>
                      </Flex>
                    ))}
                  </Flex>
                }
              >
                <Space
                  align="center"
                  style={{
                    borderRadius: 5,
                    background: "rgb(237 237 237)",
                    height: 40,
                    paddingLeft: 10,
                    paddingRight: 10,
                    marginRight: 10
                  }}
                >
                  <Image src={TokenIcon} width={25} preview={false} />
                  <Statistic
                    value={
                      tokens.freeToken.remaining +
                      tokens.subscription.remaining +
                      tokens.onetime.remaining
                    }
                    valueStyle={{ fontSize: 14, fontWeight: "bold", color: "rgb(40 57 145)" }}
                  />
                </Space>
              </Popover>
            )}
            <Dropdown menu={{ items: HelperSupportItems(userInfo) }} trigger={["click"]}>
              <a onClick={e => e.preventDefault()} style={{ display: "flex" }}>
                <Space>
                  <HelpIcon style={{ display: "block" }} />
                </Space>
              </a>
            </Dropdown>
            <Dropdown
              trigger={["click"]}
              menu={{
                items: AccountMenuItems(userInfo, handleOnOpenStripePortal, handleOnSignOut)
              }}
            >
              <a onClick={e => e.preventDefault()}>
                <Space>
                  <Avatar shape="square" style={{ backgroundColor: "#004085" }}>
                    {userInfo.firstName.charAt(0)}
                  </Avatar>
                </Space>
              </a>
            </Dropdown>
          </Space>
        </Col>
      </Row>
    </HeaderAnt>
  );
};

HeaderBar.defaultProps = {
  userInfo: userInitState.userInfo,
  classes: {}
};

HeaderBar.propTypes = {
  classes: PropTypes.shape({}),
  userInfo: userPropTypes.userInfo
};

export default withRouter(
  connectAutoDispatch(
    ({ userState }) => ({
      userInfo: userState.userInfo
    }),
    {}
  )(HeaderBar)
);
