/*eslint-disable*/
import React from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { IoIosLogIn } from "react-icons/io";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { withStyles } from "@mui/styles";
import { withRouter } from "react-router-dom";

import { compose } from "redux";
import SnackBars from "components/NewSnackbar";
import PropTypes from "prop-types";
import { enqueueSnackbar } from "notistack";
import { connectAutoDispatch } from "redux/reduxConnect";
import { userLoginRequest, userForgotPasswordRequest } from "redux/actions/UserActions";
import { userInitState, userPropTypes } from "redux/reducers/UserReducer";
import Asinzenmainlogo from "static/img/asinzenmainlogo.png";
import constants from "utils/constants";
import AInsightImg from "static/img/AInsight.png";
import ScanEzImg from "static/img/ScanEZ.png";
import WebPortalImg from "static/img/WebPortal.png";

import SignUpContainerNew from "./SignUpContainerNew";

const styles = theme => ({
  root: {
    minHeight: "100vh",
    [theme.breakpoints.down("sm")]: {
      minHeight: 700
    }
  },
  content: {
    minHeight: "100vh"
  },
  left: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#f0f7ff",
    padding: "0 6% 0 6%",
    [theme.breakpoints.down("xs")]: {
      order: 2
    },
    [theme.breakpoints.down("sm")]: {
      order: 2,
      justifyContent: "center",
      alignItems: "center",
      padding: "0 10px",
      height: "unset"
    }
  },
  right: {
    height: "100%",
    backgroundColor: "#fff",
    padding: "5% 4%",
    [theme.breakpoints.down("xs")]: {
      order: 1,
      paddingTop: 56,
      paddingBottom: 56
    },
    [theme.breakpoints.down("sm")]: {
      order: 1,
      display: "none"
    }
  },
  input: {
    overflow: "hidden",
    backgroundColor: "#fff",
    "& input": {
      padding: "12px 16px !important",
      fontSize: 16
    },
    "& fieldset": {
      borderWidth: "1px !important"
    }
  },
  loginBox: {
    backgroundColor: "#fff",
    padding: "20px 10%"
  },
  title: {
    marginTop: 16,
    fontSize: 28
  },
  loginButton: {
    backgroundColor: theme.palette.colors.deepBlue,
    color: theme.palette.colors.white
  },
  grayText: {
    fontSize: 16,
    color: theme.palette.colors.grey
  },
  blueText: {
    fontSize: 16,
    color: theme.palette.colors.deepBlue,
    cursor: "pointer"
  },
  listItem: {
    listStyleType: "disc",
    paddingTop: 16,
    paddingLeft: 32
  },
  item: {
    fontSize: 16
  }
});

class SignInContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isResetPassword: false,
      email: localStorage.getItem(constants.USER_KEY) || "",
      password: localStorage.getItem(constants.PASSWORD_KEY) || "",
      error: null,
      isBtnClick: false,
      showPassword: false,
      inputWidth: "unset",
      signupLink: "",
      remember: true
    };
  }

  componentDidMount() {
    // window.analytics.page("SignIn");
    const { location } = this.props;
    if (location.state && location.state.resetPasswordSuccess) {
      enqueueSnackbar("Your password is reset, you can try login again", {
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center"
        },
        autoHideDuration: 3000
      });
    }
    if (location.pathname === "/resetpassword") {
      this.setState({ isResetPassword: true });
    }
  }

  componentDidUpdate(prevProps) {
    const { userState } = this.props;
    if (
      prevProps.userState.callingRequestLogin &&
      !userState.callingRequestLogin &&
      !userState.errorMessage.code
    ) {
      const search = new URLSearchParams(window.location.search);
      const returnUrl = search.get("returnUrl");
      return this.props.history.push(returnUrl || "/");
    }

    if (
      prevProps.userState.callingRequestLogin &&
      !userState.callingRequestLogin &&
      userState.errorMessage.code
    ) {
      this.setState({ error: userState.errorMessage.message, isBtnClick: false });
    }

    if (
      prevProps.userState.callingForgotPassword &&
      !userState.callingForgotPassword &&
      !userState.errorMessage.code
    ) {
      enqueueSnackbar("Please check your email and open your reset password link", {
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center"
        },
        autoHideDuration: 3000
      });
      this.setState({ isBtnClick: false });
    }

    if (
      prevProps.userState.callingForgotPassword &&
      !userState.callingForgotPassword &&
      userState.errorMessage.code
    ) {
      this.setState({ isBtnClick: false, error: userState.errorMessage.message });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleForReponsive);
  }

  handleChangeTextField = keys => event => {
    this.setState({ [keys]: event.target.value });
  };

  onResetPassword = () => {
    this.props.userForgotPasswordRequest({
      email: this.state.email,
      resetType: "GET_CODE"
    });
  };

  renderErrorForAccess() {
    const { error, signupLink } = this.state;
    const { dataUserReset } = this.props;
    if (error)
      return <span className="red-c font-17" dangerouslySetInnerHTML={{ __html: error }}></span>;
    if (dataUserReset && dataUserReset.error === false) {
      return <p className="green-c font-17">{dataUserReset.message}</p>;
    }
    if (signupLink) {
      return (
        <span className="red-c font-17">
          To do so, please click
          <a href={signupLink} target="_blank" rel="noopener noreferrer">
            HERE
          </a>
          &nbsp;and follow the instruction there.
        </span>
      );
    }
    return "";
  }

  handleSignIn = () => {
    this.setState({ isBtnClick: true });
    this.props.userLoginRequest({
      email: this.state.email,
      password: this.state.password,
      remember: this.state.remember
    });
  };

  _handleChangeType = () => {
    this.setState(prevState => ({ isResetPassword: !prevState.isResetPassword, error: null }));
  };

  _handleChangeType1 = () => {
    this.setState(prevState => ({ showPassword: !prevState.showPassword }));
  };

  handleChangeRemember = () => {
    this.setState(prevState => ({ remember: !prevState.remember }));
  };

  render() {
    const {
      isResetPassword,
      email,
      password,
      isOpenSnack,
      snackVariant,
      snackMessage,
      isBtnClick,
      remember
    } = this.state;
    const { classes, match, history } = this.props;
    const isSignUp = match.path && match.path.indexOf("sign-up") >= 0;

    return (
      <Grid container className={classes.root}>
        {isOpenSnack ? (
          <SnackBars
            parSnackClose={() => this.setState({ isOpenSnack: false })}
            variant={snackVariant}
            message={snackMessage}
            isOpen={isOpenSnack}
          />
        ) : null}
        <Grid container className={classes.content}>
          <Grid item xs={12} sm={12} md={5} className={classes.left}>
            <Box width="100%" maxWidth="600px" className={classes.loginBox}>
              {!isSignUp ? (
                <Grid container>
                  <Grid item xs={12} style={{ textAlign: "center" }}>
                    <a href="https://asinzen.com/">
                      <img width="300" src={Asinzenmainlogo} />
                    </a>
                  </Grid>
                  <h1 className={classes.title}>
                    {isResetPassword ? "Reset your Password" : "Log in"}
                  </h1>
                  <Grid item xs={12} style={{ minHeight: 28, marginTop: 4 }}>
                    {this.renderErrorForAccess()}
                  </Grid>
                  <Grid item xs={12}>
                    <div style={{ fontSize: 20, marginTop: 8 }}>Email</div>
                    <FormControl fullWidth>
                      <OutlinedInput
                        classes={{ root: classes.input }}
                        value={email}
                        onChange={this.handleChangeTextField("email")}
                        placeholder="Email address"
                      />
                    </FormControl>
                  </Grid>
                  {!isResetPassword ? (
                    <Grid item xs={12}>
                      <div style={{ fontSize: 20, marginTop: 12 }}>Password</div>
                      <FormControl fullWidth>
                        <OutlinedInput
                          classes={{ root: classes.input }}
                          id="user-password"
                          type={this.state.showPassword ? "text" : "password"}
                          value={password}
                          onChange={this.handleChangeTextField("password")}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton size="small" onClick={this._handleChangeType1}>
                                {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          }
                          className="calc-input-tx1"
                          placeholder="Enter your password"
                          autoComplete="new-password"
                        />
                      </FormControl>
                    </Grid>
                  ) : null}
                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      disabled={isBtnClick}
                      style={{ marginTop: 24, padding: "8px" }}
                      onClick={() => {
                        this.setState({ isBtnClick: true }, () => {
                          if (isResetPassword === true) {
                            return this.onResetPassword("GET_CODE");
                          }
                          return this.handleSignIn();
                        });
                      }}
                      variant="contained"
                      classes={{ root: classes.loginButton }}
                    >
                      <IoIosLogIn style={{ marginRight: 5 }} />
                      {isResetPassword === true ? "Reset password" : "Log in"}
                    </Button>
                  </Grid>
                  {!isResetPassword ? (
                    <Grid item xs={12} style={{ marginTop: 24, textAlign: "right" }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={remember}
                            onChange={this.handleChangeRemember}
                            color="primary"
                          />
                        }
                        label="Remember Me"
                      />
                    </Grid>
                  ) : null}
                  <Grid item xs={12} style={{ marginTop: 12 }}>
                    <span className={classes.grayText}>
                      {isResetPassword === true ? null : "Forgot your password?"}
                    </span>
                    <span className={classes.blueText} onClick={this._handleChangeType}>
                      {isResetPassword === true ? "Log in" : " Reset Password"}
                    </span>
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: 12 }}>
                    <span className={classes.grayText}>Not registered yet?</span>
                    <span
                      className={classes.blueText}
                      onClick={() => {
                        window.open("https://goto.asinzen.com/azinsightsignup", "self");
                      }}
                    >
                      {" "}
                      Sign Up
                    </span>
                  </Grid>
                </Grid>
              ) : (
                <SignUpContainerNew match={match} history={history} />
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={7} className={classes.right}>
            <div style={{ textAlign: "center" }}>
              <img src={WebPortalImg} />
            </div>
            <div style={{ fontSize: 20, marginTop: 40 }}>Inside the web portal you can:</div>
            <ul className={classes.listItem}>
              <li className={classes.item}>Manage your subscription</li>
              <li className={classes.item}>Add, remove & change permissions for sub-accounts</li>
              <li className={classes.item}>Manage your team's Sourcing, and Buy/Sell History</li>
              <li className={classes.item}>Manage Amazon integration</li>
              <li className={classes.item}>Update your profile</li>
            </ul>
            <div style={{ fontSize: 20, marginTop: 40 }}>
              Retrieve and Analyze Data Seamlessly within Google Sheets
            </div>
            <div style={{ marginTop: 8 }}>
              <a href="https://asinzen.com/get-to-know-addon/" target="_blank" rel="noopener noreferrer">
                <img src={AInsightImg} />
              </a>
            </div>
            <div style={{ fontSize: 20, marginTop: 8 }}>
              <h4>Mobile app for Amazon Retail Arbitrage Sourcing</h4>
              <div>
                <a href="https://asinzen.com/get-to-know-scanez/" target="_blank" rel="noopener noreferrer">
                  <img src={ScanEzImg} />
                </a>
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

SignInContainer.defaultProps = {
  userState: userInitState,
  userLoginRequest
};

SignInContainer.propTypes = {
  userLoginRequest: PropTypes.func,
  userState: PropTypes.shape(userPropTypes)
};

const withConect = connectAutoDispatch(state => ({ userState: state.userState }), {
  userLoginRequest,
  userForgotPasswordRequest
});

export default compose(withConect, withRouter, withStyles(styles))(SignInContainer);
